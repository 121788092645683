import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 2rem 0;
  h2 {
    text-align: center;
    font-size: 22pt;
    padding-bottom: 2rem;
  }
`;

export const Grid = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 8fr;
  gap: 1.2rem 1.2rem;

  @media (max-width: 960px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 680px) {
    grid-template-columns: 1fr;
  }
`;

export const Item = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.11);

  h4 {
    color: ${({ theme }) => (theme === 'light' ? '#212121' : '#fff')};
  }

  p {
    color: ${({ theme }) => (theme === 'light' ? '#707070' : '#c7c7c7')};
  }
`;

export const Content = styled.div`
  padding: 1rem 0;
  min-height: 130px;
`;

export const Stats = styled.div`
  display: flex;
  align-items: center;

  div {
    display: flex;
    &:first-child {
      margin-right: 0.5rem;
    }

    img {
      margin: 0;
    }

    svg path {
      fill: ${({ theme }) => (theme === 'light' ? '#000' : '#fff')};
    }

    span {
      color: ${({ theme }) => (theme === 'light' ? '#000' : '#fff')};
      margin-left: 0.5rem;
    }
  }
`;

export const Languages = styled.div`
  opacity: 0.5;
  font-size: 14px;
`;


export const ToGithubCard = styled.div`
  box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.11);
  color: white;
  background-color: #000000;
  margin: auto 2rem;
  margin-top: ${({ length }) => (length % 2 === 0 ? '3rem' : 'auto')};
  padding: 1.0rem;
  text-align: center;
  vertical-align: middle;

  @media (max-width: 960px) {
  }

  @media (max-width: 680px) {
    margin-top: 1rem;
    padding: 0.8rem;
  }

  img {
    width: 30px;
    margin: auto;
    vertical-align: middle;
    display: inline-block;
  }

  p {
    margin-left: 1rem;
    vertical-align: middle;
    display: inline;
  }
`;