import React, { useContext } from 'react';
import { Link } from 'gatsby';
import { useIntl } from "gatsby-plugin-intl"
import { ThemeContext } from 'providers/ThemeProvider';
import { Header } from 'components/theme';
import { Container, Morpher, Button } from 'components/common';
import { Wrapper, IntroWrapper, Details, Greeting } from './styles';
import work from 'assets/illustrations/work.svg'

export const Intro = () => {
  const { theme } = useContext(ThemeContext);
  const intl = useIntl()

  // const texts = {
  //   text: "Hi there!",
  //   words: [
  //     "こんにちは!",
  //     "Ciao!",
  //     "안녕!",
  //     "Aloha!",
  //     "Всем привет!",
  //     "Hallo!",
  //     "你好呀！",
  //     "Salut!",
  //     "नमस्ते!",
  //     "Olá!",
  //     "ສະ​ບາຍ​ດີ!",
  //     "Hi there",
  //   ]
  // }

  return (
    <Wrapper>
      <Header />
      <IntroWrapper as={Container}>
        <Details theme={theme}>
          {/* <Morpher texts={texts} element="h1" /> */}
          <Greeting>
            <h2>I am Ozora,</h2>
            <h2>I’m a Full Stack Engineer!</h2>
          </Greeting>
          <img src={work} width='800rem'/>
          <p>{intl.formatMessage({ id: "index-intro-1" })}</p>
          <Link to="/contact"><Button theme={theme}>{intl.formatMessage({ id: "get-in-touch" })}</Button></Link>
        </Details>
      </IntroWrapper>
    </Wrapper>
  );
};
