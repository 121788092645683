import React, { useContext } from 'react';
import { useIntl } from "gatsby-plugin-intl"
import { ThemeContext } from 'providers/ThemeProvider';
import { Container } from 'components/common';
import { Link } from 'gatsby';
import { Wrapper, Grid, Item, Content, WrapperTools, Card } from './styles';

export const  Skills = () => {
  const { theme } = useContext(ThemeContext);
  const intl = useIntl()

const skills = [
  {
    title: "Web Engineer",
    description: intl.formatMessage({ id: "skills-web-description" }),
    subTitle1: intl.formatMessage({ id: "language-i-speak"}),
    subTitle2: intl.formatMessage({ id: "tools"}),
    description: intl.formatMessage({ id: "skills-web-description" }),
    language: "Python, TypeScript, Go",
    items: ["Django", "Flask", "React", "GCP", "AWS", "Azure"],
  },

  {
    title: "MLOps Engineer",
    description: intl.formatMessage({ id: "skills-ml-description"}),
    subTitle1: intl.formatMessage({ id: "language-i-speak"}),
    subTitle2: intl.formatMessage({ id: "tools"}),
    language: "Python, TypeScript",
    items: ["GCP", "Azure", "Kubeflow", "MLflow", "Spark"],
  },

  // {
  //   title: "Cheese Expert",
  //   description: intl.formatMessage({ id: "skills-cheese-description"}),
  //   subTitle1: intl.formatMessage({ id: "language-i-speak"}),
  //   subTitle2: "Favorite",
  //   language: intl.formatMessage({ id: "skills-cheese-language"}),
  //   items: ["Époisses", "Mothais sur Feuille", "Crottin de Chavignol"],
  // },
]

  const ListTools = (tools) => { return tools.map((tool) =>  <p>{tool}</p>) }
  console.log(skills.length)

    return (
      <Wrapper as={Container} id="projects">
        <Grid >
        {skills.map((skill) => (
         <Item  target="_blank" rel="noopener noreferrer" theme={theme}>
            <Card theme={theme}>
              <Content>
                <h3>{skill.title}</h3>
                <p>{skill.description}</p>
                <h4>{skill.subTitle1}</h4>
                <p>{skill.language}</p>
                <h4>{skill.subTitle2}</h4>
                <WrapperTools>
                  {ListTools(skill.items)}
                </WrapperTools>
              </Content>
            </Card>
          </Item>
        ))}
        </Grid>
      </Wrapper>
    );
}