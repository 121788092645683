import React, { useContext } from 'react';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container } from 'components/common';
import { Wrapper, IntroWrapper, Details, Thumbnail, LogoList, Logo } from './styles';
import { useIntl } from "gatsby-plugin-intl"

export const Companies = ({logos}) => {
  const { theme } = useContext(ThemeContext);
  const intl = useIntl()

  return (
    <Wrapper theme={theme}>
      <IntroWrapper as={Container}>
        <Details theme={theme}>

          <h2>{intl.formatMessage({ id: "companies-thanks" })}</h2>
        </Details>
        <LogoList>
          {
            logos.map((logo) => {
              return <Logo width={logo.size}><img width={logo.size} src={logo.img} /></Logo>
            })
          }
        </LogoList>
      </IntroWrapper>
    </Wrapper>
  );
};
