import styled from 'styled-components';

export const Wrapper = styled.div`
  padding-bottom: 4rem;
  text-align: center;
`;


export const Grid = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 8fr;
  // gap: 1.2rem 1.2rem;

  // @media (max-width: 960px) {
  //   grid-template-columns: repeat(2, 1fr);
  // }

  @media (max-width: 680px) {
    grid-template-columns: 1fr;
  }
`;

export const Item = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.11);

  h4 {
    color: ${({ theme }) => (theme === 'light' ? '#212121' : '#fff')};
  }

  p {
    color: ${({ theme }) => (theme === 'light' ? '#707070' : '#c7c7c7')};
  }
`;

export const Content = styled.div`
  padding: 1rem 1rem;
  min-height: 160px;
`;

export const Card = styled.div`
  padding: 1rem;
  background: ${({ theme }) => (theme === 'light' ? 'white' : '#2e2d2d')};
  height: 100%;

  h3 {
    color: ${({ theme }) => (theme === 'light' ? 'black' : 'white')};
    padding-top: 1.4rem;
  }

  h4 {
    color: ${({ theme }) => (theme === 'light' ? '#42a5f5' : '#42a5f5')};
    margin-bottom: 1rem;
    margin-top: 3.3rem;
    font-weight: normal;
    font-size: 18px;
  }
  p {
    color: ${({ theme }) => (theme === 'light' ? '#707070' : '#c7c7c7')};
  }


  @media (max-width: 680px) {
    margin: 0.8rem;
  }

`;


export const WrapperTools = styled.div`
  p {
    margin: 0.6rem;
  }
`;
