import React, { useContext } from 'react';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container, ProjectsCard } from 'components/common';
import { Link } from 'gatsby';
import { Wrapper, Grid, Item, Content, Title } from './styles';

export const  Projects = (props) => {
  const { theme } = useContext(ThemeContext);
    return (
      <Wrapper as={Container} id="projects">
        <Title><h2>{props.title}</h2></Title>
        <Grid len={props.data.length}>
          {
            props.data.map((d) => (
          <Item  as="a" href={d.url} target="_blank" rel="noopener noreferrer" theme={theme}>
            <ProjectsCard theme={theme}>
              <Content>
                <h4>{d.name}</h4>
                <p>{d.description}</p>
              </Content>
              <Link to={d.to}>See detail</Link>
            </ProjectsCard>
          </Item>
            ))
          }
        </Grid>
      </Wrapper>
    );
}