import styled from 'styled-components';

export const Wrapper = styled.div`
  border-top: ${({ theme }) => (theme === 'light' ? '0.1px solid #dddddd' : '0.1px solid #2d2d2d')};
  border-bottom: ${({ theme }) => (theme === 'light' ? '0.1px solid #dddddd' : '0.1px solid #2d2d2d')};
  background-size: contain;
  background-position: right top;
  background-repeat: no-repeat;
  @media (max-width: 960px) {
    padding-bottom: 2rem;
    flex-direction: column;
`;

export const IntroWrapper = styled.div`
  padding-top: 4rem;
  align-items: center;
  text-align: center;
  justify-content: space-between;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const Details = styled.div`
  flex: 1;

  @media (max-width: 960px) {
    width: 100%;
    margin-bottom: 2rem;
  }

  h1 {
    margin-bottom: 2rem;
    font-size: 22pt;
    color: ${({ theme }) => (theme === 'light' ? '#00e676' : '#ffa000')};

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }

    @media (max-width: 680px) {
      font-size: 20pt;
    }
  }

  h2 {
    margin-bottom: 2rem;
    font-size: 20pt;
    color: ${({ theme }) => (theme === 'light' ? '#373737' : '#e6e6e6')};

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }

    @media (max-width: 680px) {
      font-size: 16pt;
    }
  }

  h4 {
    margin-bottom: 1rem;
    font-size: 20pt;
    font-weight: normal;

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }

    @media (max-width: 680px) {
      font-size: 16pt;
    }
  }
`;

export const Thumbnail = styled.div`
  flex: 1;

  @media (max-width: 960px) {
    width: 100%;
  }

  img {
    width: 100%;
  }
`;

export const LogoList = styled.div`
  padding-top: 1rem;
  height: 100%;
  vertical-align: middle;
  align-items: center;
`;

export const Logo = styled.div`
  padding: 1rem;
  display: inline-block; 
  margin: auto;
  vertical-align: middle;
`;