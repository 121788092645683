import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import axios from 'axios';
import { Container, Card, TitleWrap } from 'components/common';
import Star from 'components/common/Icons/Star';
import Fork from 'components/common/Icons/Fork';
import githubIcon from 'assets/icons/GitHub-Mark-Light-120px-plus.png'
import { Wrapper, Grid, Item, Content, Stats, Languages, ToGithubCard } from './styles';

export class GithubRepos extends React.Component {
  state = {
    contents: [],
  }
  componentDidMount() {
    this.props.data.map((d) => {
      axios.get("https://api.github.com/repos/" + d.owner + "/" + d.name)
        .then((res) => {
          let data = {
            id: res.data.id,
            owner: res.data.owner.login,
            name: res.data.name,
            description: res.data.description,
            stars: res.data.stargazers_count,
            forks: res.data.forks,
            url: res.data.svn_url,
          }
          data = (<Item key={data.id} as="a" href={data.url} target="_blank" rel="noopener noreferrer" theme={this.props.theme}>
            <Card theme={this.props.theme}>
              <Content>
                <h4>{data.name}</h4>
                <p>{data.description}</p>
              </Content>
              <TitleWrap>
                <Stats theme={this.props.theme}>
                  <div>
                    <Star color={this.props.theme === "light" ? "#000" : "#fff"} />
                    <span>{data.stars}</span>
                  </div>
                  <div>
                    <Fork color={this.props.theme === "light" ? "#000" : "#fff"} />
                    <span>{data.forks}</span>
                  </div>
                </Stats>
                <Stats theme={this.props.theme}>
                  <Languages>
                    <span>
                      {data.language}
                    </span>
                  </Languages>
                </Stats>
              </TitleWrap>
            </Card>
          </Item>)
          let contents = this.state.contents.slice();
          contents.push(data)
          this.setState({ contents: contents })
        })
    })

  }
  render() {
    return (
      <Wrapper as={Container} id="projects">
        <h2>{this.props.title}</h2>
        <Grid>
          {this.state.contents}
          <a href="https://github.com/ozora-ogino" rel="noopener noreferrer" target="_blank">
            <ToGithubCard length={this.props.data.length}>
              <img src={githubIcon} />
              <p>See more</p>
            </ToGithubCard>
          </a>
        </Grid>
      </Wrapper>
    );
  }
}
